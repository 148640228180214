import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ethers } from 'ethers';
import { useReferral } from '../App';
import PresaleMenu from './PresaleMenu';
import ProductCard from './presale/ProductCard';
import ShoppingCart from './presale/ShoppingCart';
import ProductPopup from './presale/ProductPopup';
import NotificationPopup from './presale/NotificationPopup';
import Cookies from 'js-cookie';
import './Presale.css';

const products = [
  {
    name: 'AI Nomad PRO',
    description: 'The ultimate companion for the resilient explorer',
    details: 'This portable device is designed for the harshest environments with a focus on robustness and self-sufficiency. Features include an emergency power bank, Level 2 EMP protection compliant with MIL-STD-461G, and advanced electrical safety through GFCI (Ground Fault Circuit Interrupter) for superior electrical shock protection. It supports decentralized data storage, operates fully offline, and utilizes NVIDIA Jetson Orin NX 16GB for advanced AI tasks. Additional features include M.2 AI accelerators, high-capacity SSD storage, dedicated microcontrollers for enhanced hotword detection, and additional AI accelerators for optimized voice interaction.',
    price: 3000,
    image: 'path/to/nomad-pro-image.jpg',
    youtubeLink: 'https://www.youtube.com/watch?v=NpEaa2P7qZI',
    benefits: ['Robust design for harsh environments', 'Advanced AI capabilities'],
  },
  {
    name: 'AI Burg PRO',
    description: 'Your personal fortress of solitude',
    details: 'Equipped with NVIDIA Tesla A100 Ampere 40 GB GPUs for high-demand AI processing, this device serves as a comprehensive AI training and smart home management hub. Features large-scale storage solutions, sophisticated home automation capabilities, and an energy management system with PE (Protective Earth) fault protection to ensure operational safety and efficiency. It supports predominantly offline operation to enhance data privacy and allows users to generate passive income by renting out its processing power for external AI training tasks.',
    price: 25000,
    image: 'path/to/burg-pro-image.jpg',
    youtubeLink: 'https://www.youtube.com/watch?v=NpEaa2P7qZI',
    benefits: ['High-performance AI processing', 'Smart home management'],
  },
  {
    name: 'AI Agents - Cloud Only',
    description: 'Cloud-based AI assistant for digital nomads',
    details: 'Provides state-of-the-art cloud-based AI services with high accessibility and performance. Tailored for users who demand flexibility, this service offers comprehensive virtual assistance, automated task management, and user-specific customization. It utilizes cutting-edge AI technology for real-time, intuitive user interactions, suitable for those seeking a powerful, efficient AI assistant without the need for physical hardware.',
    price: 500,
    image: 'path/to/ai-agents-image.jpg',
    youtubeLink: 'https://www.youtube.com/watch?v=NpEaa2P7qZI',
    benefits: ['Flexibility and accessibility', 'Powerful virtual assistance'],
  }
];

function Presale() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [walletAddress, setWalletAddress] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [ethPrice, setEthPrice] = useState(null);
  const { referrals } = useReferral();
  const hasLoggedVisit = useRef(false); // Use a ref to track if the page visit has been logged

  const logPageVisit = useCallback(async () => {
    if (hasLoggedVisit.current) return; // Prevent multiple logs
    hasLoggedVisit.current = true;

    const duration = 0; // Duration is zero for initial page load
    const storedReferrals = referrals.length ? referrals : JSON.parse(Cookies.get('referrals') || '[]');
    const data = { referrals: storedReferrals, duration, path: window.location.pathname };
    console.log('Logging page visit:', data); // Log the data
    try {
      const response = await fetch('https://join.btfo.ai/api/logPageVisit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to log page visit');
      }
      console.log('Page visit logged successfully');
    } catch (error) {
      console.error('Error logging page visit:', error);
    }
  }, [referrals]);

  useEffect(() => {
    fetchEthPrice().then(price => setEthPrice(price));
    logPageVisit(); // Log the page visit when the component is mounted
  }, [logPageVisit]);

  const fetchEthPrice = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      const data = await response.json();
      return data.ethereum.usd;
    } catch (error) {
      console.error('Failed to fetch ETH price:', error);
      return null;
    }
  };

  const handleAddToCart = (product) => {
    if (cartItems.length >= 1) {
      setPopupMessage("STRICTLY LIMITED, MAX ONE PRE ORDER PER WALLET");
      setShowPopup(true);
    } else {
      setCartItems([product]);
    }
  };

  const handleRemoveFromCart = (productToRemove) => {
    setCartItems(currentItems => currentItems.filter(item => item.name !== productToRemove.name));
  };

  const handleClosePopup = () => {
    setSelectedProduct(null);
    setShowPopup(false);
  };

  const handleLearnMore = (product) => {
    setSelectedProduct(product);
  };

  const productWithEthPrices = products.map(product => ({
    ...product,
    ethPrice: ethPrice ? (product.price / ethPrice).toFixed(4) : "Loading..."
  }));

  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  const handlePurchase = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signature = await signer.signMessage("Test");
        console.log('Signature:', signature);
      } catch (error) {
        console.error('Error signing message:', error);
      }
    } else {
      console.log('No Ethereum provider is available');
    }
  };

  const firstReferral = referrals.length > 0 ? referrals[0].code : 'No referral';

  return (
    <div className="presale-shop-page">
      <PresaleMenu 
        walletAddress={walletAddress} 
        setWalletAddress={setWalletAddress}
      />
      <div className="presale-container">
        <h1 className="presale-title">Liquidity bootstrapping - PRE ORDER</h1>
        <div className="presale-product-grid">
          {productWithEthPrices.map((product, index) => (
            <ProductCard
              key={index}
              name={product.name}
              description={product.description}
              price={`$${product.price} USD / ${product.ethPrice} ETH`}
              image={product.image}
              onLearnMore={() => handleLearnMore(product)}
              onAddToCart={() => handleAddToCart(product)}
            />
          ))}
        </div>
        <ShoppingCart 
          items={cartItems}
          totalPrice={`$${totalPrice}`}
          walletAddress={walletAddress}
          onBuy={handlePurchase}
          onRemove={handleRemoveFromCart}
          firstReferral={firstReferral} // Pass the first referral
        />
      </div>
      {selectedProduct && (
        <ProductPopup product={selectedProduct} onClose={handleClosePopup} />
      )}
      {showPopup && (
        <NotificationPopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default Presale;
