import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './WelcomePre.css';
import './Home.css';
import { useReferral } from '../App';

function WelcomePre() {
    const [activeQuestion, setActiveQuestion] = useState(null);
    const { referrals } = useReferral();
    const [ipAddress, setIpAddress] = useState('');

    useEffect(() => {
        // Fetch the IP address
        const fetchIpAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setIpAddress(data.ip);
                console.log('Fetched IP address:', data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIpAddress();

        const startTime = Date.now();

        const logPageVisit = async (duration) => {
            // Ensure referrals is correctly passed
            const storedReferrals = referrals.length ? referrals : JSON.parse(Cookies.get('referrals') || '[]');
            const data = { referrals: storedReferrals, duration, path: window.location.pathname, ipAddress };
            console.log('Logging page visit:', data);  // Log the data
            try {
                const response = await fetch('https://join.btfo.ai/api/logPageVisit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                if (!response.ok) {
                    throw new Error('Failed to log page visit');
                }
                console.log('Page visit logged successfully');
            } catch (error) {
                console.error('Error logging page visit:', error);
            }
        };

        const handleClick = (event) => {
            logClick(event.target);
        };

        const logClick = async (target) => {
            const data = {
                referrals,
                path: window.location.pathname,
                element: target.tagName,
                id: target.id,
                class: target.className,
                ipAddress
            };
            console.log('Logging click event:', data);  // Log the data
            try {
                const response = await fetch('https://join.btfo.ai/api/logClick', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                if (!response.ok) {
                    throw new Error('Failed to log click event');
                }
                console.log('Click event logged successfully');
            } catch (error) {
                console.error('Error logging click event:', error);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            const duration = Date.now() - startTime;
            logPageVisit(duration);
            document.removeEventListener('click', handleClick);
        };
    }, [referrals, ipAddress]);

    const toggleFAQ = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    return (
        <div className="welcome-pre-page">
            <div className="generation-headline">
                <h1>Welcome to the Future with New Order AI!</h1>
                <p>As we introduce $BTFO, intricately integrated with the advanced Robot Operating System (ROS) technologies. We invite you to participate in our unique pre-seed crowdfunding event, which not only grants you early access to privacy-centric technology but also provides substantial financial benefits. Your participation directly enhances your stake in a future dedicated to safeguarding individual sovereignty.</p>
                <p>By integrating Real World Assets (RWA), each device you preorder is connected to a verifiable digital identity, enhancing transparency and linking your investment directly to tangible products. At New Order AI, we're setting new standards for data privacy and security, utilizing blockchain's robust features to protect both your digital and physical realms from external threats.</p>
                <p>Our pre-seed funding round is more than an investment opportunity—it's a chance to redefine data privacy and personal autonomy.</p>
            </div>
            <div className="participation-methods">
                <div className="method-container">
                    <Link to="/presale" className="option-container clickable">
                        <div className="method-description">
                            <strong>Participate Through Our DApp</strong>
                            <p>Use our DApp for a guided experience managing blockchain transactions. Simple and straightforward, it provides essential tools for tracking and managing your investments.</p>
                        </div>
                        <button className="method-button">Explore DApp</button>
                    </Link>
                </div>
                <div className="method-container">
                    <div className="option-container clickable" onClick={() => console.log('Direct Blockchain Interaction')}>
                        <div className="method-description">
                            <strong>Direct Blockchain Interaction</strong>
                            <p>Prefer direct control? Participate by sending ETH directly to our smart contract, bypassing traditional interfaces for a pure blockchain experience.</p>
                        </div>
                        <button className="method-button">Send ETH Directly</button>
                    </div>
                </div>
            </div>
            <div className="video-section">
                <h2>Learn More About Our Products</h2>
                <div className="video-placeholder">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/your_video_id"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
            <div className="faq-section">
                <div className="faq-title">Frequently Asked Questions</div>
                <div className="faq-container">
                    <div className={`faq-item ${activeQuestion === 0 ? 'open' : ''}`} onClick={() => toggleFAQ(0)}>
                        <div className="faq-question">What are the benefits of participating in the pre-launch?</div>
                        <p className="faq-answer">Participants receive 50% cashback in usage for our AI services and vested $BFTO tokens over six months, enhancing the value of your investment significantly.</p>
                    </div>
                    <div className={`faq-item ${activeQuestion === 1 ? 'open' : ''}`} onClick={() => toggleFAQ(1)}>
                        <div className="faq-question">How do I participate in the presale directly via blockchain?</div>
                        <p className="faq-answer">To participate directly, send ETH to the specified smart contract address. This method ensures your transaction is processed on-chain, adhering to principles of decentralization.</p>
                    </div>
                    <div className={`faq-item ${activeQuestion === 2 ? 'open' : ''}`} onClick={() => toggleFAQ(2)}>
                        <div className="faq-question">What security measures are in place for transactions?</div>
                        <p className="faq-answer">We utilize advanced cryptographic techniques to secure transactions and ensure data integrity, adhering to the highest blockchain security standards.</p>
                    </div>
                    <div className={`faq-item ${activeQuestion === 3 ? 'open' : ''}`} onClick={() => toggleFAQ(3)}>
                        <div className="faq-question">Can I transfer my $BFTO tokens to another wallet?</div>
                        <p className="faq-answer">Yes, $BFTO tokens can be transferred to any ERC-20 compatible wallet after they are vested and claimable as per the schedule.</p>
                    </div>
                    <div className={`faq-item ${activeQuestion === 4 ? 'open' : ''}`} onClick={() => toggleFAQ(4)}>
                        <div className="faq-question">Are there any geographical restrictions on participation?</div>
                        <p className="faq-answer">Participants should ensure they comply with their local regulations regarding cryptocurrency investments as restrictions may vary by jurisdiction.</p>
                    </div>
                    <div className={`faq-item ${activeQuestion === 5 ? 'open' : ''}`} onClick={() => toggleFAQ(5)}>
                        <div className="faq-question">What is the minimum investment required?</div>
                        <p className="faq-answer">The minimum investment threshold is detailed in the official presale documentation, available on our website and through our DApp.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcomePre;
