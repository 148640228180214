import React from 'react';
import './ProductPopup.css';

function ProductPopup({ product, onClose }) {
  return (
    <div className="product-popup-overlay">
      <div className="product-popup-content">
        <span onClick={onClose} className="product-popup-close">X</span>
        <h2 className="product-popup-title">{product.name}</h2>
        <div className="product-text-content">
          <p>{product.details}</p>
        </div>
        <div className="product-benefits-video">
          <div className="product-benefits">
            <div className="product-benefits-title">Benefits:</div>
            <ul>
              {product.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </div>
          <div className="product-video-placeholder">
          <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/u31qwQUeGuM"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
    referrerPolicy="strict-origin-when-cross-origin"
  ></iframe>
</div>
        </div>
        <div className="product-action-buttons">
          <span onClick={onClose} className="product-action-button">Close</span>
          <a href="https://docs.neworder.ai" target="_blank" rel="noopener noreferrer" className="product-action-button">Learn more</a>
        </div>
      </div>
    </div>
  );
}

export default ProductPopup;