import React, { useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';
import Loader from './components/Loader';
import StartWithProject from './components/startwithproject';
import Presale from './components/Presale';
import WelcomePre from './components/WelcomePre';
import './components/Home.css';
import { v4 as uuidv4 } from 'uuid';

const ReferralContext = createContext();

export const useReferral = () => useContext(ReferralContext);

const ReferralProvider = ({ children }) => {
  const location = useLocation();
  const [referrals, setReferrals] = useState([]);
  const [sessionToken, setSessionToken] = useState(Cookies.get('sessionToken') || '');

  useEffect(() => {
    // Generate a session token if it doesn't exist
    if (!sessionToken) {
      const newSessionToken = uuidv4();
      Cookies.set('sessionToken', newSessionToken, { path: '/', secure: true, sameSite: 'Strict' });
      setSessionToken(newSessionToken);
      console.log(`Generated new session token: ${newSessionToken}`);
    } else {
      console.log(`Existing session token: ${sessionToken}`);
    }

    // Retrieve referrals from cookies on initial load
    const existingReferrals = JSON.parse(Cookies.get('referrals') || '[]');
    setReferrals(existingReferrals);

    const params = new URLSearchParams(location.search);
    const referral = params.get('referral');
    if (referral) {
      console.log(`Referral code found in URL: ${referral}`);
      updateReferrals(referral);
    }
  }, [location.search, sessionToken]);

  const updateReferrals = (newReferral) => {
    setReferrals((prevReferrals) => {
      const exists = prevReferrals.find(ref => ref.code === newReferral);
      if (exists) {
        console.log(`Referral code already exists: ${newReferral}`);
        return prevReferrals;
      }

      if (prevReferrals.length >= 3) {
        console.log(`Referral code ignored, already have 3 referrals: ${newReferral}`);
        return prevReferrals;
      }

      const updatedReferrals = [
        ...prevReferrals,
        { code: newReferral, timestamp: Date.now(), order: `referral-${prevReferrals.length + 1}` }
      ];

      // Store referrals in cookies
      Cookies.set('referrals', JSON.stringify(updatedReferrals), { path: '/' });
      console.log(`Updated referrals: ${JSON.stringify(updatedReferrals)}`);
      return updatedReferrals;
    });
  };

  return (
    <ReferralContext.Provider value={{ referrals, sessionToken }}>
      {children}
    </ReferralContext.Provider>
  );
};


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<WelcomePre />} />
        <Route path="/startwithproject" element={<StartWithProject />} />
        <Route path="/presale" element={<Presale />} />
        <Route path="*" element={<Loader />} />
      </Routes>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <ReferralProvider>
        <App />
      </ReferralProvider>
    </Router>
  );
}
